<div class="self-vistoria-background">
  <div class="self-vistoria-header-container">
    <div class="self-vistoria-header">
      <div class="self-vistoria-text-header">Vistoria do carro</div>
      <div><img src="{{ companyLogo }}"
                class="company-logo vistoria"/></div>
    </div>

    <div class="container-stepper">
      <label class="carro-step"
             matBadge="1">Carro</label>
      <label class="documentos-step"
             matBadge="2">Documentos</label>
    </div>
  </div>

  <div class="container-self-information">
    <div class="container-text-seft-photo">
      <p class="text-form">
        Para enviar as fotos da vistoria, toque em cada uma das imagens abaixo.
      </p>
    </div>

    <div class="container-stepper alert">
      <p>
        Dica importante: tire as fotos durante o dia, ou num local com boa
        iluminação.<br>
        Dúvidas no processo de vistoria?
        <a rel="noopener noreferrer" href="https://youtu.be/PVR1hZOryRI?si=JGWT-P43PNi3Txxa"
                    target="_blank">Clique aqui
        </a>
      </p>
    </div>

    <ng-container *ngIf="proposal">
      <div class="container-btns">
        <div *ngFor="let picture of proposal.pictures; trackBy: utilService.resourceTrackBy"
             class="container-btn-photo position-relative">

          <div class="file-upload form-group col-xs-2 col-md-3 button-fechamento-adicionar-container non-padding">

            <ng-container *ngIf="!proposal.proposal.isPending; else isProposalPendingBlock">
              <div (click)="getOrientationPermission(picture)"
                   [attr.data-dd-action-name]="'Tirar foto: ' + picture.description"
                   *ngIf="!picture.takenPicture; else hasTakenPictureBlock"
                   class="button-fechamento-adicionar icon">
                <img src="{{ picture.icon }}"
                     alt="Imagem explicando como a foto deve ser tirada nesse espaço"
                     class="image-self-photo"/>
              </div>

              <ng-template #hasTakenPictureBlock>
                <div (click)="getOrientationPermission(picture)"
                     [attr.data-dd-action-name]="'Substituir foto: ' + picture.description"
                     class="button-fechamento-adicionar">
                  <img alt="Ícone de edição"
                       class="edit-icon"
                       src="/assets/img/edit-icon.svg"/>
                  <img src="{{ picture.takenPicture }}"
                       alt="Foto tirada pelo usuário"
                       class="image-self-photo"/>
                </div>
              </ng-template>
            </ng-container>

            <ng-template #isProposalPendingBlock>

              <ng-container *ngIf="picture.isPending; else pictureNotPendingBlock">
                <div (click)="getOrientationPermission(picture)"
                     [attr.data-dd-action-name]="'Tirar/Substituir foto de proposta pendente: ' + picture.description">
                  <ng-container *ngIf="!picture.takenPicture; else hasTakenPendingPictureBlock">
                    <div class="button-fechamento-adicionar icon">
                      <img src="{{ picture.icon }}"
                           alt="Imagem explicando como a foto deve ser tirada nesse espaço"
                           class="image-self-photo"/>
                    </div>
                  </ng-container>

                  <ng-template #hasTakenPendingPictureBlock>
                    <div class="button-fechamento-adicionar">
                      <img alt="Ícone de edição"
                           class="edit-icon"
                           src="/assets/img/edit-icon.svg"/>
                      <img src="{{ picture.takenPicture }}"
                           alt="Foto tirada pelo usuário"
                           class="image-self-photo"/>
                    </div>
                  </ng-template>
                </div>
              </ng-container>

              <ng-template #pictureNotPendingBlock>
                <div *ngIf="!!picture.takenPicture"
                     class="button-fechamento-adicionar">
                  <img alt="Ícone de finalizado"
                       class="edit-icon"
                       src="/assets/img/done-icon.png"/>
                  <img alt="Ícone de preto e branco de imagem enviada"
                       class="image-self-photo"
                       src="{{ picture.sentIcon }}"/>
                </div>
              </ng-template>

            </ng-template>

          </div>

          <label class="text-btn-photo">{{ picture.description }}</label>
        </div>
      </div>
    </ng-container>

    <div class="col-12 container-erro-text-message">
      <label class="erro-text-message">{{ messageErro }}</label>
    </div>

    <div class="col-12 container-erro-text-message">
      <label class="alert-text-message">
        Antes de continuar, confira se as fotos estão nítidas e mostrando
        o ângulo correto.
      </label>
    </div>

    <button class="layout-btn-overall layout-btn-label-overall"
            [ngClass]="{
            success: canSubmitPictures(),
            fail: !canSubmitPictures()
            }"
            [disabled]="!canSubmitPictures()"
            (click)="submit()">
      Continuar
    </button>
  </div>
</div>

<div class="footer">
  <mat-icon class="material-symbols-outlined">
    lock
  </mat-icon>
  <span>usebens.com.br</span>
</div>

<app-player (onCapture)="onCapture($event)"></app-player>
